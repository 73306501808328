import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Avatar, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AccountBalanceOutlined, AccountCircleOutlined, AddCardOutlined, Approval, CasinoOutlined, ContactSupportOutlined, ContactSupportSharp, CurrencyRupeeSharp, DataArrayOutlined, EmojiEventsOutlined, FactCheckOutlined, Home, HomeOutlined, InfoOutlined, LocalActivityOutlined, LockResetOutlined, Login, LoginRounded, Logout, LogoutOutlined, LogoutRounded, MoveDownOutlined, ReceiptLongOutlined, SettingsSuggestOutlined, Star, SummarizeOutlined, TransformOutlined, Wallet, WalletOutlined } from '@mui/icons-material';
import { get, isEmpty } from 'lodash';
import { authActions } from '../store/reducer/auth-reducer';
import { ReactComponent as UserIcon } from '../assets/user-circle-svgrepo-com.svg';
import CustomModal from './CustomModal';
import Loginuser from '../pages/login/loginuser';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const CustomAppBar = ({ userData, walletBalance, pageName }: any) => {
  const userDataSession: any = sessionStorage.getItem('userData');
  const finalData = JSON.parse(userDataSession)

  const [loginModal, setLoginModal] = React.useState<any>(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const pathData = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const profileClick = () => {
    navigate('/my-profile');
    handleMenuClose();
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleNavigate = () => {
    handleMenuClose()
    navigate('/login')
  }

  const handleLogout: any = () => {
    sessionStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('userData');
    dispatch(authActions.resetUserData())
    // window.location.reload()
    navigate('/');
    // setTimeout(() => {
    //   navigate('/');
    // }, 100)
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (

    !isEmpty(userData) && <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={profileClick}>Profile</MenuItem>
      <MenuItem onClick={handleLogout} >Logout</MenuItem>
    </Menu>

  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const routeList: any = [   
    {
      group: '',
      children: [        
        {
          path: '/',
          name: 'Home',
          hide: true,
          disabled: false,
          icon: <HomeOutlined />
        },
        {
          path: '/home',
          name: 'Home ',
          disabled: false,
          icon: <HomeOutlined />
        },
        {
          path: '/upload-pg-data',
          name: 'Upload QR Details ',
          disabled: isEmpty(userData),
          icon: <DataArrayOutlined />,
          hide : (get(finalData,'user_type',0) == 1) ?false :true
        },
        {
          path: '/approve-payments/:userid/:orderid',
          name: 'Approve Points',
          disabled: isEmpty(userData),
          icon: <Approval />,
          hide : (get(finalData,'user_type',0) == 1) ?false :true
        },
        {
          path: '/play-game/:game_id',
          name: 'Home',
          hide: true,
          disabled: isEmpty(userData),
          icon: <CasinoOutlined />
        },
        {
          path: '/my-profile',
          name: 'My Profile',
          disabled: isEmpty(userData),
          icon: <AccountCircleOutlined />
        },
        // {
        //   path: '/add-point',
        //   name: 'Add Points',
        //   disabled: isEmpty(userData),
        //   icon: <AddCardOutlined/>
        // },
        {
          path: '/add-point',
          name: 'Add Points',
          disabled: isEmpty(userData),
          icon: <AddCardOutlined />
          },
        {
              path: '/transfer-points',
              name: 'Transfer Points',
              disabled: isEmpty(userData),
              icon: <TransformOutlined />
          },
        {
          path: '/withdraw-points',
          name: 'Withdraw Points',
          disabled: isEmpty(userData),
          icon: <WalletOutlined />
        },
        {
          path: '/wallet-statement',
          name: 'Wallet Statement',
          disabled: isEmpty(userData),
          icon: <ReceiptLongOutlined />
        },
        {
          path: '/winning-history',
          name: 'Winning History',
          disabled: isEmpty(userData),
          icon: <EmojiEventsOutlined />
        },
        {
          path: '/bid-history',
          name: 'Bid History',
          disabled: isEmpty(userData),
          icon: <FactCheckOutlined />
        },
        {
          path: '/transfer-points',
          name: 'Transfer Points',
          hide: true,
          disabled: isEmpty(userData),
          icon: <MoveDownOutlined />
        },
      ]
    },
    {
      group: '',
      children: [
        {
          path: '/starline',
          name: 'Starline Games',
          disabled: false,
          icon: <LocalActivityOutlined />
        },
        {
          path: '/starline/bidhistory',
          name: 'Starline Bid History',
          disabled: isEmpty(userData),
          icon: <FactCheckOutlined />
        },
        {
          path: '/starline/winhistory',
          name: 'Starline Win History',
          disabled: isEmpty(userData),
          icon: <EmojiEventsOutlined />
        },
      ]
    },

    {
      group: '',
      children: [
        {
          path: '/manage-bank-details',
          name: 'Manage bank details',
          disabled: isEmpty(userData),
          icon: <AccountBalanceOutlined />
        },
        {
          path: '/upi-setting',
          name: 'UPI Settings',
          disabled: isEmpty(userData),
          icon: <SettingsSuggestOutlined />
        },
        {
          path: '/change-password',
          name: 'Change Password',
          disabled: isEmpty(userData),
          icon: <LockResetOutlined />
        },
        {
          path: '/game-rates',
          name: 'Game Rates',
          disabled: false,
          icon: <SummarizeOutlined />
        },
        {
          path: '/how-to-play',
          name: 'How to play?',
          disabled: false,
          icon: <InfoOutlined />
        },
        {
          path: '/contact-us',
          name: 'Contact Us',
          disabled: false,
          icon: <ContactSupportOutlined />
        },

      ]
    }



  ]

  const menuList: any = {
    'home': {
      path: 'home',
      name: '9 Ank',
      disabled: false
    },
    'play-game/:game_id': {
      path: 'play-game/:game_id',
      name: '9 Ank',
      hide: true,
      disabled: isEmpty(userData)
    },
    'my-profile': {
      path: 'my-profile',
      name: 'My Profile',
      disabled: isEmpty(userData)
    },
    // 'add-point': {
    //   path: 'add-point',
    //   name: 'Add Points',
    //   disabled: isEmpty(userData)
    // }
    'add-point': {
      path: 'add-point',
      name: 'Add Points',
      disabled: isEmpty(userData)
    },
    'withdraw-points': {
      path: 'withdraw-points',
      name: 'Withdraw Points',
      disabled: isEmpty(userData)
    },
    'wallet-statement': {
      path: 'wallet-statement',
      name: 'Wallet Statement',
      disabled: isEmpty(userData)
    },
    'winning-history': {
      path: 'winning-history',
      name: 'Winning History',
      disabled: isEmpty(userData)
    },
    'bid-history': {
      path: 'bid-history',
      name: 'Bid History',
      disabled: isEmpty(userData)
    },
    'transfer-points': {
      path: 'transfer-points',
      name: 'Transfer Points',
      disabled: isEmpty(userData),
      hide: true
    },
    'manage-bank-details': {
      path: 'manage-bank-details',
      name: 'Manage bank details',
      disabled: isEmpty(userData)
    },
    'upi-setting': {
      path: 'upi-setting',
      name: 'UPI Settings',
      disabled: isEmpty(userData)
    },
    'game-rates': {
      path: 'game-rates',
      name: 'Game Rates',
      disabled: false
    },
    'how-to-play': {
      path: 'how-to-play',
      name: 'How to play?',
      disabled: false
    },
    'contact-us': {
      path: 'contact-us',
      name: 'Contact Us',
      disabled: false
    },
    'change-password': {
      path: 'change-password',
      name: 'Change Password',
      disabled: isEmpty(userData)
    },
    'starline': {
      path: '/starline',
      name: 'Starline Games',
      disabled: false
    },
    'starline/bidhistory': {
      path: '/starline/bidhistory',
      name: 'Starline Bid History',
      disabled: isEmpty(userData)
    },
    'starline/winhistory': {
      path: '/starline/winhistory',
      name: 'Starline Win History',
      disabled: isEmpty(userData)
    },
    'play-game/game_id': {
      path: '/play-game/:game_id',
      name: 'Starline Win History',
      disabled: isEmpty(userData)
    },
    'login': {
      path: '/login',
      name: 'Login',
      disabled: isEmpty(userData)
    },
    'register': {
      path: '/register',
      name: 'Register',
      disabled: isEmpty(userData)
    },
    'payment': {
      path: '/payment',
      name: 'Payment',
      disabled: isEmpty(userData)
    },
    'success': {
      path: '/success',
      name: 'Payment Success',
      disabled: isEmpty(userData)
    },
    'failed': {
      path: '/failed',
      name: 'Payment Failed',
      disabled: isEmpty(userData)
    },
    'upload-pg-data': {
      path: '/upload-pg-data',
      name: 'Upload QR Details',
      disabled: isEmpty(userData)     
    },   
    'approve-payments':{
      path: '/approve-payments',
      name: 'Approve Points',
      disabled: isEmpty(userData),     
    }
  }


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        height: '150px',
        borderBottom: '1px solid #f1f5ff',
        marginBottom: '10px',
        backgroundColor: '#1c274c',
        color: 'white',
        paddingLeft: '30px'
      }}>
        <IconButton sx={{ height: '100px', width: '100px', paddingLeft: 0 }}>
          <UserIcon style={{ height: '100px', width: '100px' }} />
        </IconButton>
        <Typography sx={{ marginTop: '5px', textTransform: 'capitalize', fontFamily: 'cursive', fontSize: '0.8rem' }}>Welcome, {(isEmpty(userData) ? 'Guest' : get(finalData, 'user_name', 'Guest'))}</Typography>
      </Box>
      {routeList.map((item: any, index: number) => (
        <List disablePadding sx={{ padding: 0, }} >
          {item.children.map((item: any, index: number) => (
            !item.hide && <> {!item.disabled && <ListItem disablePadding key={item.name} sx={{ padding: 0, paddingLeft: '15px' }} >
              <ListItemButton onClick={() => navigate(item.path)} sx={{
                color: '#1c274c',
                padding: 0,
                paddingTop: '8px',
                paddingBottom: '8px',
              }}>
                <ListItemIcon sx={{
                  color: '#1c274c',
                  padding: 0,
                  minWidth: '30px'
                }}>
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  {item.icon}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: '0.5rem', '.MuiTypography-root': { fontSize: '1rem' } }} primary={item.name} />
              </ListItemButton>
            </ListItem>}</>
          ))}
          <Divider sx={{ borderColor: '#e1e8f8' }} />
        </List>
      ))}
      {!isEmpty(userData) && <List sx={{ padding: 0, }}>
        <ListItem key={'logout'} disablePadding sx={{ padding: 0, paddingLeft: '15px' }}>
          <ListItemButton onClick={handleLogout} sx={{
            color: '#1c274c',
            padding: 0,
            paddingTop: '6px',
            paddingBottom: '6px',
          }}>
            <ListItemIcon sx={{
              color: '#1c274c',
              padding: 0,
              minWidth: '30px'
            }}>
              <LogoutRounded />
            </ListItemIcon>
            <ListItemText sx={{ fontSize: '0.5rem', '.MuiTypography-root': { fontSize: '0.8rem' } }} primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List>}
    </Box>
  );


  let menuNameApp: any = ''
  let _pathData: any = pathData?.pathname.split('/')
 
  if (_pathData.includes('play-game')) {
    menuNameApp = pageName
  } else if (_pathData.includes('starline')) {
    if (_pathData.includes('game-list')) {
      menuNameApp = pageName
    } else if (_pathData.includes('place-bid')) {
      menuNameApp = pageName
    } else {
      menuNameApp = menuList[pathData.pathname.replace('/', '')]['name']
    }
  } else if (_pathData.includes('approve-payments')) { 
    menuNameApp = 'Approve Payment'
  }else {

    if (_pathData.filter((item: any) => item != '').length > 0) {
      menuNameApp = menuList[pathData.pathname.replace('/', '')]['name']
    } else {
      menuNameApp = 'Matka App'
    }

  }

  React.useEffect(()=>{
    document.title = menuNameApp;
  },[menuNameApp])
  return (
    <div style={{ width: '100%' }}>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: '#f1f5ff', color: '#1c274c' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0.5, padding: '0px 5px 0px 5px', borderRadius: 0 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon sx={{ height: '32px', width: '32px' }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="inherit"
            sx={{ display: { xs: 'block', sm: 'block' }, textTransform: 'capitalize', fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px' } }}
          >
            {/* {(pathData.pathname.replace('/', '') != '' && pathData.pathname.replace('/', '') != 'home') ? pathData.pathname.replace('/', '').replace('-', " ") : 'SAI GAMES'} */}
            {menuNameApp}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box>
            {/* <IconButton  color="inherit" sx={{ borderRadius: 0 }} onClick={() => navigate('/starline')}>
              <Star />
              <Typography sx={{ marginLeft: '5px', fontSize: '14px' }}> Star Line</Typography>
            </IconButton> */}

            {!isEmpty(userData) ?
              <IconButton color="inherit" sx={{ backgroundColor: '#a9c2ff', padding: '3px 10px 3px 10px', borderRadius: '22px', fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '16px', xl: '16px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => navigate('/add-point')}>
                <Wallet sx={{ height: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' }, width: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' } }} />
                <Typography sx={{ marginLeft: '5px', fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' } }}> {walletBalance.wallet_amt}</Typography>
              </IconButton> : <IconButton color="inherit" sx={{ marginLeft: '10px', backgroundColor: '#a9c2ff', padding: '3px 10px 3px 10px', borderRadius: '22px', fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '16px', xl: '16px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => navigate('/login')}>
                <LoginRounded sx={{ height: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' }, width: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' } }} />
                <Typography sx={{ marginLeft: '5px', fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' } }}> Login</Typography>
              </IconButton>}

          </Box>
          {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box> */}
          {/* {!isEmpty(userData) && <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            <IconButton color="inherit" sx={{ backgroundColor: '#a9c2ff', padding: '3px 10px 3px 10px', borderRadius: '22px', fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '16px', xl: '16px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleLogout}>
              <LogoutOutlined sx={{ height: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' }, width: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' } }} />
              <Typography sx={{ marginLeft: '5px', fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' } }}> Login</Typography>
            </IconButton>
          </Box>
          } */}
          {/* <Box sx={{ display: { xs: 'none', md: 'none' } }}>
            <IconButton

              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={toggleDrawer(false)} sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#f1f5ff', color: '#1c274c', '.MuiList-root': {
            color: '#1c274c',
          }
        }
      }}>
        {DrawerList}
      </Drawer>
      {/* <CustomModal open={loginModal} customstyle={{ width: '200px', backgroundColor: '#FFF', padding: '16px' }}>
       <Loginuser/>
      </CustomModal> */}
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  pageName: state.starline.menuName,
})


export default connect(mapStateToProps)(CustomAppBar);
